import React, { useState } from "react";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import type { CryptoSymbol } from "@/feature-crypto/constants.mjs";
import { CRYPTO_TOKENS } from "@/feature-crypto/constants.mjs";
import CryptoBalance from "@/feature-crypto/CryptoBalance.jsx";
import { cryptoRefs } from "@/feature-crypto/refs.mjs";
import { PortfolioTile } from "@/feature-wallet/PortfolioTile.jsx";
import { ErrorOverlay } from "@/shared/ErrorOverlay.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

interface PortfolioCardProps {
  cryptoSymbol: CryptoSymbol;
}

export default function PortfolioCryptoTile({
  cryptoSymbol,
}: PortfolioCardProps) {
  const cryptoToken = CRYPTO_TOKENS[cryptoSymbol];

  const { isAvailable } = useSnapshot(cryptoRefs);

  const {
    crypto: { balances },
  } = useSnapshot(readState);

  const [isBalanceUpdating, setIsBalanceUpdating] = useState(false);

  if (
    cryptoToken.minimumVisibleBalance !== undefined &&
    (balances[cryptoToken.ticker] ?? 0n) < cryptoToken.minimumVisibleBalance
  ) {
    return null;
  }

  return (
    <PortfolioTile>
      <div className="action">
        <div className="wrapper">
          <cryptoToken.Icon size={64} />
        </div>
      </div>

      <CardTitle className="title">
        <div className="balance">
          <ShowBalance
            cryptoSymbol={cryptoSymbol}
            onAnimationStateChange={setIsBalanceUpdating}
            suffix="shortName"
            showTooltip
            {...classNames("type-body1", isBalanceUpdating && "animating")}
          />
        </div>
      </CardTitle>

      {!isAvailable && <ErrorOverlay />}
    </PortfolioTile>
  );
}

const CardTitle = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sp-2);

  .balance {
    display: flex;
    flex-direction: column;
    gap: var(--sp-1);

    color: var(--shade0);
  }

  .conversion {
    color: var(--shade1);
  }
`;

const ShowBalance = styled(CryptoBalance)`
  margin: calc(var(--sp-2) * -1);
  padding: var(--sp-2);

  border-radius: 10em;

  &,
  .digits,
  .suffix {
    transition:
      background-color 0.25s ease-out,
      color 0.25s ease-out;
  }

  &.animating {
    background-color: hsla(var(--turq-hsl) / 0.1);

    &,
    .digits,
    .suffix {
      color: var(--turq);

      transition: none;
    }
  }
`;
