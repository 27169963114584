import React, { useCallback, useState } from "react";
import { Button } from "clutch/src/Button/Button.jsx";
import * as uuid from "uuid";

import { kryptonite } from "@/feature-crypto/kryptonite.mjs";
import { KryptoniteError } from "@/feature-crypto/models/model-kryptonite-error.mjs";
import type { KryptoniteTransaction } from "@/feature-crypto/models/model-kryptonite-transaction.mjs";
import { PendingTransactionState } from "@/feature-crypto/PendingTransactionState.jsx";
import { cryptoRefs } from "@/feature-crypto/refs.mjs";
import { GameSharkSection } from "@/feature-gameshark/GameSharkSection.jsx";
import { devError } from "@/util/dev.mjs";

export function CryptoGameSharkSection() {
  return (
    <GameSharkSection title="Crypto">
      <PurchaseProductButton />
      <PurchaseSubscriptionButton />
    </GameSharkSection>
  );
}

function PurchaseProductButton() {
  const [idempotencyKey, setIdempotencyKey] = useState<string | null>(() =>
    uuid.v4(),
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>(null);

  const doBuy = useCallback(async () => {
    setIsSubmitting(true);

    const productResponse = await kryptonite.getProduct({
      productId: "8d7340bc-86fa-4594-9ba9-28c6a8b782a0",
    });

    if (productResponse instanceof KryptoniteError) {
      devError(productResponse);

      return;
    }

    const purchaseResponse = await kryptonite.purchaseProduct({
      idempotencyKey,

      productId: productResponse.id,
      walletId: cryptoRefs.wallet.id,
    });

    if (purchaseResponse instanceof KryptoniteError) {
      devError(purchaseResponse);

      return;
    }

    const signature = await cryptoRefs.wallet.signTypedData(
      purchaseResponse.params.domain,
      {
        ForwardRequest: [
          { name: "from", type: "address" },
          { name: "to", type: "address" },
          { name: "value", type: "uint256" },
          { name: "gas", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "data", type: "bytes" },
        ],
      },
      {
        from: purchaseResponse.params.from,
        to: purchaseResponse.params.to,
        value: purchaseResponse.params.value,
        gas: purchaseResponse.params.gas,
        nonce: purchaseResponse.params.nonce,
        data: purchaseResponse.params.data,
      },
    );

    const submitResponse = await kryptonite.submitTransaction({
      request: purchaseResponse,
      signature,
    });

    if (submitResponse instanceof KryptoniteError) {
      devError(submitResponse);
      return;
    }

    setTransactionId(submitResponse.id);
  }, [idempotencyKey]);

  const onTransactionUpdated = useCallback(
    (tranasaction: KryptoniteTransaction) => {
      if (
        tranasaction.status === "confirmed" ||
        tranasaction.status === "failed"
      ) {
        setIsSubmitting(false);

        setTimeout(() => {
          setIdempotencyKey(uuid.v4());
          setTransactionId(null);
        }, 2500);
      }
    },
    [],
  );

  return (
    <Button block onClick={doBuy} loading={isSubmitting}>
      {transactionId ? (
        <PendingTransactionState
          tx={transactionId}
          Pending={TransactionPending}
          Submitted={TransactionSubmitted}
          Confirmed={TransactionConfirmed}
          Failed={TransactionFailed}
          onUpdated={onTransactionUpdated}
        />
      ) : (
        "Buy something in-app"
      )}
    </Button>
  );
}

function PurchaseSubscriptionButton() {
  const [idempotencyKey, setIdempotencyKey] = useState<string | null>(() =>
    uuid.v4(),
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>(null);

  const doSubscription = useCallback(async () => {
    setIsSubmitting(true);

    const paySubscriptionResponse = await kryptonite.paySubscription({
      idempotencyKey,

      walletId: cryptoRefs.wallet.id,
      numDays: 1,
    });

    if (paySubscriptionResponse instanceof KryptoniteError) {
      devError(paySubscriptionResponse);

      return;
    }

    const signature = await cryptoRefs.wallet.signTypedData(
      paySubscriptionResponse.params.domain,
      {
        ForwardRequest: [
          { name: "from", type: "address" },
          { name: "to", type: "address" },
          { name: "value", type: "uint256" },
          { name: "gas", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "data", type: "bytes" },
        ],
      },
      {
        from: paySubscriptionResponse.params.from,
        to: paySubscriptionResponse.params.to,
        value: paySubscriptionResponse.params.value,
        gas: paySubscriptionResponse.params.gas,
        nonce: paySubscriptionResponse.params.nonce,
        data: paySubscriptionResponse.params.data,
      },
    );

    const submitResponse = await kryptonite.submitTransaction({
      request: paySubscriptionResponse,
      signature,
    });

    if (submitResponse instanceof KryptoniteError) {
      devError(submitResponse);
      return;
    }

    setTransactionId(submitResponse.id);
  }, [idempotencyKey]);

  const onTransactionUpdated = useCallback(
    (tranasaction: KryptoniteTransaction) => {
      if (
        tranasaction.status === "confirmed" ||
        tranasaction.status === "failed"
      ) {
        setIsSubmitting(false);

        setTimeout(() => {
          setIdempotencyKey(uuid.v4());
          setTransactionId(null);
        }, 2500);
      }
    },
    [],
  );

  return (
    <Button block onClick={doSubscription} loading={isSubmitting}>
      {transactionId ? (
        <PendingTransactionState
          tx={transactionId}
          Pending={TransactionPending}
          Submitted={TransactionSubmitted}
          Confirmed={TransactionConfirmed}
          Failed={TransactionFailed}
          onUpdated={onTransactionUpdated}
        />
      ) : (
        "Buy 1 day subscription"
      )}
    </Button>
  );
}

function TransactionPending() {
  return <div>{"pending"}</div>;
}

function TransactionSubmitted() {
  return <div>{"submitted"}</div>;
}

function TransactionConfirmed() {
  return <div>{"confirmed"}</div>;
}

function TransactionFailed() {
  return <div>{"failed"}</div>;
}
