import React from "react";
import { subscribeKey } from "valtio/utils";

import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  readState,
} from "@/__main__/app-state.mjs";
import { IS_DEV } from "@/__main__/constants.mjs";
import appRefs from "@/app/refs.mjs";
import type { User } from "@/feature-auth/models/user-model.mjs";
import {
  clearUserData,
  initWallet,
  loadUserWallet,
  resetInitWallet,
} from "@/feature-crypto/actions.mjs";
import { CRYPTO_AVAX_SYMBOL } from "@/feature-crypto/constants.mjs";
import { fetchUserPaymentInfo } from "@/feature-crypto/fetches/fetch-user-payment-info.mjs";
import { CryptoGameSharkSection as GameSharkCryptoSection } from "@/feature-crypto/GameSharkCryptoSection.jsx";
import { kryptonite } from "@/feature-crypto/kryptonite.mjs";
import { KryptoniteError } from "@/feature-crypto/models/model-kryptonite-error.mjs";
import type { KryptoniteSubscriptionResponse } from "@/feature-crypto/models/model-kryptonite-subscription.mjs";
import PortfolioCryptoTile from "@/feature-crypto/PortfolioCryptoTile.jsx";
import { cryptoRefs } from "@/feature-crypto/refs.mjs";
import type { StoredCryptoWallet } from "@/feature-crypto/wallet.mjs";
import { gamesharkRefs } from "@/feature-gameshark/refs.mjs";
import { subscriptionRefs } from "@/feature-subscriber/utils/subscription.refs.mjs";
import { walletRefs } from "@/feature-wallet/refs.mjs";
import routesRefs from "@/routes/routes.refs.mjs";
import addInitialPath from "@/util/add-initial-path.mjs";
import { devDebug } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import lazyFn from "@/util/lazy-fn.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import subscriptionStateDiff from "@/util/subscription-state-diff.mjs";

const fetchSubscriptionInfo = lazyFn(
  () => import("@/feature-crypto/fetches/subscription.mjs"),
);

const original = mapOriginalRefs({
  walletRefs,
  appRefs,
  gamesharkRefs,
  routesRefs,
});

let unsubscribeUser: () => void;
let unsubscribeActiveSubscription: () => void;
let unsubscribeWalletRef: () => void;
let unsubscribeIsFocused: () => void;

export function setup() {
  addInitialPath(["crypto"], {
    kryptonite: {
      auth: undefined,
    },

    wallet: undefined,
    balances: {},
    transactions: {},

    subscription: undefined,
  });

  unsubscribeUser = subscribeKey(
    readState,
    "user",
    subscriptionStateDiff(onUserChanged, readState.user),
  );

  unsubscribeActiveSubscription = subscribeKey(
    readState.crypto,
    "subscription",
    updateActiveSubscriptionRef,
  );

  unsubscribeWalletRef = subscribeKey(
    readState.crypto,
    "wallet",
    subscriptionStateDiff(onWalletChanged, readState.crypto.wallet),
  );

  unsubscribeIsFocused = subscribeKey(
    readState.volatile,
    "isFocused",
    onWindowFocusedChanged,
  );

  original.set({
    gamesharkRefs: {
      CryptoSection: GameSharkCryptoSection,
    },
  });

  original.append({
    walletRefs: {
      fetchFunctions: [fetchSubscriptionInfo],

      currencies: [
        () =>
          React.createElement(PortfolioCryptoTile, {
            cryptoSymbol: CRYPTO_AVAX_SYMBOL,
          }),
      ],
    },
    routesRefs: {
      defaultRouteSideEffects: [fetchUserPaymentInfo],
    },
  });

  globals.document.addEventListener(
    "visibilitychange",
    onWindowVisibilityChange,
  );

  initWallet().catch((error) => {
    if (IS_DEV) {
      devDebug("FAILED TO INITIALIZE CRYPTO WALLET", error);
    } else {
      throw error;
    }
  });
}

export async function teardown() {
  await initWallet();

  original.restore();

  globals.document.removeEventListener(
    "visibilitychange",
    onWindowVisibilityChange,
  );

  unsubscribeUser();
  unsubscribeActiveSubscription();
  unsubscribeWalletRef();
  unsubscribeIsFocused();

  delete writeState.crypto;

  resetInitWallet();
}

async function onUserChanged(next?: User, prev?: User) {
  if (next?.id === prev?.id) return;

  await initWallet();

  if (!next?.id) {
    clearUserData();

    return;
  }

  if (!readState.crypto.wallet) {
    try {
      await loadUserWallet();
    } catch (error) {
      if (IS_DEV) {
        devDebug("FAILED TO LOAD CRYPTO WALLET", error);
      } else {
        throw error;
      }
    }
  }
}

function updateActiveSubscriptionRef(
  subscription: KryptoniteSubscriptionResponse,
) {
  if (subscription === undefined || subscription instanceof KryptoniteError) {
    subscriptionRefs.activeSubscription = null;
  } else {
    subscriptionRefs.activeSubscription = {
      status: "active",
      willCancelAtPeriodEnd: !subscription.renews,
      createdAt: subscription.startAt,
      currentPeriodEnd: subscription.endAt,
    };
  }
}

function onWalletChanged(next?: StoredCryptoWallet, prev?: StoredCryptoWallet) {
  if (next?.id === prev?.id) return;

  if (prev?.id) {
    kryptonite.removeWalletListener({
      walletId: prev.id,
    });
  }

  if (next?.id) {
    if (
      globals.document.visibilityState === "hidden" ||
      readState.volatile?.isFocused === false
    ) {
      return;
    }

    kryptonite.addWalletListener({
      walletId: next.id,
    });
  }
}

/// The amount of time to wait before removing wallet listeners when
/// the window is hidden.
const VISIBILITY_TIMEOUT: number = 1000 * 60 * 2.5;

let visibilityTimeoutId: Timeout | null = null;

function onVisible() {
  clearTimeout(visibilityTimeoutId);

  if (cryptoRefs.wallet) {
    kryptonite.addWalletListener({
      walletId: cryptoRefs.wallet.id,
    });
  }
}

function onNotVisible() {
  visibilityTimeoutId = setTimeout(() => {
    if (cryptoRefs.wallet) {
      devDebug("[kryptonite] removing wallet listener due to inactivity");

      kryptonite.removeWalletListener({
        walletId: cryptoRefs.wallet.id,
      });
    }
  }, VISIBILITY_TIMEOUT);
}

function onWindowVisibilityChange() {
  if (globals.document.visibilityState === "hidden") {
    onNotVisible();
  } else {
    // In the app window visibility state may be visible but not focused.
    if (readState.volatile?.isFocused === false) {
      return;
    }

    onVisible();
  }
}

function onWindowFocusedChanged(isFocused: boolean) {
  if (isFocused === false) {
    onNotVisible();
  } else {
    onVisible();
  }
}
