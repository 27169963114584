import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { initAuth } from "@/feature-auth/utils/auth-actions.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import * as API from "@/feature-wallet/api.mjs";
import { UserPaymentMethodsModel } from "@/feature-wallet/models/user-payment-methods.mjs";
import { devError } from "@/util/dev.mjs";

const day = 1000 * 60 * 60 * 24;
export function fetchUserPaymentInfo() {
  async function sideEffect() {
    await initAuth();

    const user = readState.user;
    const bearerToken = await getBearerToken();
    const isLoggedIn = Boolean(user && bearerToken);
    if (!isLoggedIn) return;

    return Promise.all([
      getData(
        API.getUserPaymentMethods(),
        UserPaymentMethodsModel,
        ["wallet", "paymentProviders"],
        {
          headers: { Authorization: bearerToken },
          shouldFetchIfPathExists: false,
          expiryTime: Date.now() + day,
        },
      ),
    ]);
  }

  sideEffect().catch((error) => {
    devError("ERROR LOADING USER PAYMENT INFO", error);
  });
}
